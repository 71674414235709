import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

const GlobalCSS = () => (
  <GlobalStyles
    id="globalStyles"
    styles={{
      body: {
        // remove user agent style for CLS
        margin: 0,
      },
      a: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          color: "#00c235",
        },
      },
      ".MuiTypography-colorSecondary": {
        color: "black",
      },
      ".blog-post-article": {
        maxWidth: "50rem",
        margin: "auto",
        paddingLeft: 20,
        paddingRight: 20,
      },
      "blog-post-container": {
        "& p": {
          fontSize: "1rem",
        },
      },
    }}
  />
);

export default GlobalCSS;
