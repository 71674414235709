module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/util/styles/emotion-cache-props.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-D1KG7ZLPGJ"],"gtagConfig":{"cookie_expires":0,"anonymize_ip":true},"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"quality":50,"showCaptions":true,"withWebp":true,"loading":"lazy","markdownCaptions":true,"linkImagesToOriginal":false,"backgroundColor":"white","withAvif":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"icon":"<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>","className":"custom-class","maintainCase":true,"removeAccents":true,"isIconAfterHeader":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Hydroplanner","name":"Build your own hydroponics system","description":"Use the Hydroplanner to define the perfect environment for your hydroponic system and find the perfect PH and TDS parameters","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","include_favicon":true,"localize":[{"start_url":"/de/","lang":"de","name":"Baue dein eigenes Hydroponics System","short_name":"Hydroplanner","description":"Verwende den Hydroplanner um die perfekten Bedingungen für dein Hydroponics system zu schaffen in dem du die perfekten pH und TDS Werte findest."}],"icons":[{"src":"favicon.ico","sizes":"64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"logo192.png","type":"image/png","sizes":"192x192"},{"src":"logo512.png","type":"image/png","sizes":"512x512"},{"src":"logo128.png","type":"image/png","sizes":"128x128"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"en","siteUrl":"https://hydroplanner.com","trailingSlash":"never","i18nextOptions":{"lowerCaseLng":true,"interpolation":{"escapeValue":false},"keySeparator":true,"saveMissing":true,"updateMissing":true,"appendNamespaceToMissingKey":true},"redirect":false,"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"languages":["de","en"]},{"matchPath":"/:lang?/blog","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
