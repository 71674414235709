import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = responsiveFontSizes(
  createTheme({
    components: {
      body: {
        margin: 0,
      },
      a: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          color: "#00c235",
        },
      },
      ".MuiTypography-colorSecondary": {
        color: "black",
      },
      "blog-post-container": {
        "& p": {
          fontSize: "1rem",
        },
      },
    },
    typography: {
      h1: {
        fontWeight: 350,
      },
      h2: {
        fontWeight: 500,
      },
      body1: {
        lineHeight: 2,
      },
      lead: {
        fontsize: "1.4rem",
        fontWeight: 400,
      },
    },
    palette: {
      //Erstellt mit https://material.io/inline-tools/color/
      primary: {
        lightest: "#e4f8e8",
        lighter: "#90e1a1",
        light: "#58d678",
        main: "#00CC59",
        dark: "#00c235",
        darker: "#00b22b",
        darkest: "#00800D",
        contrastText: "#ffffff",
      },
      grey: {
        main: "#a9a9a9",
        contrastText: "#fff",
      },
      secondary: {
        main: "#2F80ED",
        contrastText: "#fff",
      },
      background: {
        default: "#FFFFFF",
        contrastText: "#000000",
      },
    },
  }),
  { factor: 4 }
);

export default theme;
