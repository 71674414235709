import * as React from "react";
import createCache from "@emotion/cache";
import emotionCacheProps from "./emotion-cache-props";
import { StyledEngineProvider } from "@mui/material/styles";

export const cache = createCache(emotionCacheProps);

export function GlobalCssPriority({ children }) {
  return (
    // <CacheProvider value={cache}>
    <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    // </CacheProvider>
  );
}
