import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import muiTheme from "./src/util/styles/theme";
import GlobalCss from "./src/util/styles/GlobalCss";
import { GlobalCssPriority } from "./src/util/styles/CSSPriority";
import "./src/styles/root.scss";
import "./src/styles/main.scss";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={muiTheme}>
    <GlobalCssPriority>
      <CssBaseline />
      <GlobalCss />
      {element}
    </GlobalCssPriority>
  </ThemeProvider>
);
